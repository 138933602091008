import React, { useEffect } from "react";
import { observer } from "mobx-react";
import styled from "styled-components";
import View from "../../../components/atoms/view";
import { scaler } from "../../../helper/scaler";
import { useNavigate, useParams } from "react-router-dom";
import { defaultURLParamType } from "../../../helper/queryUrlHelper";
import paymentSuccessStore from "../../../store/paymentSuccessStore";
import { initInstance } from "../../../factories/axiosFactory";
import orderService from "../../../service/orderService";
import { ExtraChargePaymentDTO } from "../../../interfaces/order/api";
import CompleteImage from "../../../assets/successful/complete.png";
import { localeNumber } from "../../../helper/numberHelper";
import spinnerStore from "../../../store/spinnerStore";
import BottomDescription from "../../../components/templates/bottomDescription";
import TOS from "../../../components/templates/TOS";
import termOfServiceModalStore from "../../../modalStore/termOfServiceModalStore";
import privatePolicyModalStore from "../../../modalStore/privetePolicyModalStore";
import paymentStore from "../../../store/paymentStore";
import ExtraChargeCard from "../../../components/atoms/extraChargeCard";

const Layout = styled(View)`
  flex: 1;
`;

const InnerLayout = styled(View)`
  padding: ${({ theme }) => theme.webBase.templatePadding}px;
  @media (max-width: 768px) {
    padding: ${({ theme }) => theme.appBase.templatePadding}px;
  }
`;

const CompleteView = styled(View)`
  align-items: center;
  margin-bottom: 24px;
  @media (max-width: 768px) {
    margin-bottom: ${scaler(16)}px;
  }
`;

const Complete = styled.img`
  width: 50px;
  height: 50px;
  @media (max-width: 768px) {
    width: ${scaler(25)}px;
    height: ${scaler(25)}px;
  }
`;

const LayoutTitleView = styled(View)`
  align-items: center;
  margin-bottom: 32px;
  @media (max-width: 768px) {
    margin-bottom: ${scaler(24)}px;
  }
`;

const LayoutTitle = styled(View)`
  font-size: ${({ theme }) => theme.webTypography.size.el1}px;
  font-weight: ${({ theme }) => theme.webTypography.weight.semiBold};
  @media (max-width: 768px) {
    font-size: ${({ theme }) => theme.appTypography.size.el1}px;
  }
`;

const TitleView = styled(View)`
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 12px;
  @media (max-width: 768px) {
    margin-bottom: ${scaler(8)}px;
  }
`;

const Title = styled(View)`
  font-size: ${({ theme }) => theme.webTypography.size.m3}px;
  font-weight: ${({ theme }) => theme.webTypography.weight.semiBold};
  @media (max-width: 768px) {
    font-size: ${({ theme }) => theme.appTypography.size.m3}px;
  }
`;

const SeqText = styled(View)`
  font-size: ${({ theme }) => theme.webTypography.size.m1}px;
  color: ${({ theme }) => theme.colors.black[100]};
`;

const SeqView = styled(View)`
  margin-bottom: 12px;
`;

const ExtraModelView = styled(View)`
  margin-bottom: 24px;
`;

const StatusTextView = styled(View)`
  align-items: flex-end;
`;

const StatusText = styled(View)`
  font-size: ${({ theme }) => theme.webTypography.size.m1}px;
  color: ${({ theme }) => theme.colors.gray[500]};
  @media (max-width: 768px) {
    font-size: ${({ theme }) => theme.appTypography.size.m1}px;
  }
`;

const PaymentSuccessful = observer(() => {
  const navigation = useNavigate();
  const params: defaultURLParamType = useParams();
  const initParams = (urlParams: defaultURLParamType) => {
    if (!urlParams.host) {
      navigation("/NotFound");
      return;
    }
    if (!urlParams?.p) {
      navigation("/NotFound");
      return;
    }
    // All URL params is valid.
    paymentSuccessStore.setHost(urlParams.host);
    paymentSuccessStore.setP(urlParams?.p);
  };

  // 결제 완료된 송장이 존재하는지 확인합니다.
  const validatePayment = (paymentData: ExtraChargePaymentDTO) => {
    const donePayment = paymentData.status === "DONE";
    if (!donePayment) {
      navigation("/NotFound");
      return;
    }
    paymentSuccessStore.setDonePayment(paymentData);
  };

  const initPaymentData = async () => {
    try {
      spinnerStore.setLoading(true);
      const response = await orderService.getPaymentData(params.p + "");
      if (response) {
        paymentSuccessStore.setDonePayment(response);
        validatePayment(response);
      }
      spinnerStore.setLoading(false);
    } catch (error: any) {
      spinnerStore.setLoading(false);
      console.log("error =>", error);
    }
  };

  useEffect(() => {
    initParams(params);
    initInstance(paymentSuccessStore.hostUrl);
    initPaymentData();
  }, []);

  return (
    <Layout>
      <InnerLayout>
        <CompleteView>
          <Complete src={CompleteImage} />
        </CompleteView>
        <LayoutTitleView>
          <LayoutTitle>{`${paymentSuccessStore.donePayment.orderName} 고객님`}</LayoutTitle>
          <LayoutTitle>결제가 완료되었습니다.</LayoutTitle>
        </LayoutTitleView>

        <SeqView>
          <SeqText>{`주문번호 : ${paymentSuccessStore.donePayment.orderSeq}`}</SeqText>
          <SeqText>{`송장번호 : ${paymentSuccessStore.donePayment.invoiceSeq}`}</SeqText>
          <SeqText>{`결제번호 : ${paymentSuccessStore.donePayment.paymentSeq}`}</SeqText>
        </SeqView>

        <TitleView>
          <Title>결제내역</Title>
        </TitleView>
        {(paymentStore.paymentData?.extraChargesComponent?.length || 0) > 0 && (
          <>
            <TitleView>
              <Title>자재</Title>
            </TitleView>

            <ExtraModelView>
              {paymentStore.paymentData.extraChargesComponent?.map(
                (extraModel, index) => (
                  <ExtraChargeCard
                    key={`extraCharges-${index}`}
                    columnName={extraModel.columnName || ""}
                    price={extraModel.price || 0}
                    quantity={extraModel.quantity || 0}
                    chargeFlag={extraModel.chargeFlag || true}
                  />
                )
              )}
            </ExtraModelView>
          </>
        )}

        {(paymentStore.paymentData?.extraChargesService?.length || 0) > 0 && (
          <>
            <TitleView>
              <Title>서비스</Title>
            </TitleView>

            <ExtraModelView>
              {paymentStore.paymentData.extraChargesService?.map(
                (extraModel, index) => (
                  <ExtraChargeCard
                    key={`extraCharges-service-${index}`}
                    columnName={extraModel.columnName || ""}
                    price={extraModel.price || 0}
                    quantity={extraModel.quantity || 0}
                    chargeFlag={extraModel.chargeFlag || true}
                  />
                )
              )}
            </ExtraModelView>
          </>
        )}

        <TitleView>
          <Title>최종결제금액</Title>
          <Title>{`${localeNumber(
            paymentSuccessStore.totalPurchase
          )} 원`}</Title>
        </TitleView>

        <StatusTextView>
          <StatusText>결제완료</StatusText>
        </StatusTextView>
      </InnerLayout>

      <InnerLayout>
        <TOS
          onPressTOS={() => termOfServiceModalStore.handleModal(true)}
          onPressPrivatePolicy={() => privatePolicyModalStore.handleModal(true)}
        />
      </InnerLayout>

      <InnerLayout>
        <BottomDescription />
      </InnerLayout>
    </Layout>
  );
});

export default PaymentSuccessful;
