import {
  getResultKey,
  getResultAmount,
  getResultOrderId,
  getResultP,
} from "../helper/queryUrlHelper";
import {
  makeObservable,
  runInAction,
  action,
  observable,
  computed,
} from "mobx";
import { IStore } from "../helper/storeHelper";
import { ExtraChargeTossPaymentDTO } from "../interfaces/order/api";
import orderService from "../service/orderService";
import { HOST_LIST, HOST_TYPES } from "../constants/hostType";

interface IPaymentResultStore {
  host: string;
  invoiceSeq: string;
  p: string;
  _orderId: string | undefined;
  _paymentKey: string | undefined;
  _amount: number | undefined;
}

const initialState: IPaymentResultStore = {
  host: "",
  p: "",
  invoiceSeq: "",
  _orderId: undefined,
  _paymentKey: undefined,
  _amount: undefined,
};

class PaymentResultStore implements IStore {
  host = initialState.host;
  p = initialState.p;
  invoiceSeq = initialState.invoiceSeq;
  _orderId = initialState._orderId;
  _paymentKey = initialState._paymentKey;
  _amount = initialState._amount;

  constructor() {
    makeObservable(this, {
      host: observable,
      p: observable,
      invoiceSeq: observable,
      _orderId: observable,
      _paymentKey: observable,
      _amount: observable,

      requestPayment: action.bound,
      clear: action.bound,

      setHost: action.bound,
      setP: action.bound,
      setInvoiceSeq: action.bound,

      setResultValues: action.bound,

      resultKey: computed,
      resultAmount: computed,
      resultOrderId: computed,
      hostUrl: computed,
    });
  }

  async requestPayment() {
    // NOTE: You should already validate invoiceSeq & paymentKey.
    const body: ExtraChargeTossPaymentDTO = {
      invoiceSeq: parseInt(this.invoiceSeq),
      orderId: `${this.resultOrderId}`,
      paymentKey: this.resultKey!,
    };
    // console.log("body =>", body);
    const response = await orderService.requestPayment(body);
    return response;
  }

  setP(p: string) {
    runInAction(() => {
      this.p = p;
    });
  }

  setHost(host: HOST_TYPES) {
    runInAction(() => {
      this.host = host;
    });
  }

  setInvoiceSeq(invoiceSeq: string) {
    runInAction(() => {
      this.invoiceSeq = invoiceSeq;
    });
  }

  private setResultKey(key: string) {
    runInAction(() => {
      this._paymentKey = key;
    });
  }

  private setResultAmount(amount: number) {
    runInAction(() => {
      this._amount = amount;
    });
  }

  private setResultOrderId(orderId: string) {
    runInAction(() => {
      this._orderId = orderId;
    });
  }

  setResultValues(query: string): boolean {
    const resultKey = getResultKey(query);
    const resultAmount = getResultAmount(query);
    const p = getResultP(query);
    const resultOrderId = getResultOrderId(query);

    if (!resultKey || !resultAmount || !resultOrderId || !p) {
      return false;
    }
    // Valid key, amount, invoiceSeq
    this.setP(p);
    this.setResultKey(resultKey);
    this.setResultAmount(resultAmount);
    this.setResultOrderId(resultOrderId);
    return true;
  }

  get resultKey() {
    return this._paymentKey;
  }

  get resultAmount() {
    return this._amount;
  }

  get resultOrderId() {
    return this._orderId;
  }

  get hostUrl(): HOST_LIST {
    switch (this.host) {
      case "host":
        return HOST_LIST.HOST;
      case "body":
        return HOST_LIST.HOST_BODY;
      case "ch_zf":
        return HOST_LIST.HOST_CH_ZF;
      default:
        return HOST_LIST.HOST_DEV;
    }
  }

  clear() {
    runInAction(() => {
      this._orderId = initialState._orderId;
      this._paymentKey = initialState._paymentKey;
      this._amount = initialState._amount;
    });
  }
}

const paymentResultStore = new PaymentResultStore();
export default paymentResultStore;
