import { observer } from "mobx-react";
import React from "react";
import styled from "styled-components";
import { localeNumber as localeNumber } from "../../helper/numberHelper";
import View from "./view";

const Layout = styled(View)`
  padding: 10px 0;
  border-bottom-style: solid;
  border-bottom-width: 2px;
  border-bottom-color: ${({ theme }) => theme.colors.gray[100]};
`;

export const Flex = styled(View)`
  display: flex;
  flex-direction: row;
`;

export const FlexBetween = styled(View)`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
`;

const ModelName = styled(View)`
  font-size: ${({ theme }) => theme.webTypography.size.m1}px;
  color: ${({ theme }) => theme.colors.black[100]};
  margin-bottom: 8px;
`;

const BottomLayout = styled(View)`
  flex-direction: row;
`;

const Quantity = styled(View)`
  margin: 0 5px;
  font-size: ${({ theme }) => theme.webTypography.size.m1}px;
  color: ${({ theme }) => theme.colors.gray[500]};
`;

const Price = styled(View)`
  font-size: ${({ theme }) => theme.webTypography.size.m1}px;
  font-weight: ${({ theme }) => theme.webTypography.weight.semiBold};
  color: ${({ theme }) => theme.colors.primary.blue};
`;

const PriceCancel = styled(View)`
  font-size: ${({ theme }) => theme.webTypography.size.m1}px;
  font-weight: ${({ theme }) => theme.webTypography.weight.semiBold};
  color: ${({ theme }) => theme.colors.gray[300]};
  text-decoration: line-through;
`;

const Gap = styled(View)`
  border-style: solid;
  border-width: 1px;
  border-color: ${({ theme }) => theme.colors.gray[300]};
  height: 12px;
  margin: 0 12px;
`;

interface IExtraChargesCard {
  columnName: string;
  price: number;
  quantity: number;
  chargeFlag: boolean;
}

const ExtraChargeCard: React.FC<IExtraChargesCard> = observer((props) => {
  return (
    <Layout>
      <FlexBetween>
        <ModelName>{props.columnName}</ModelName>
        <Flex>
          {props.chargeFlag ? (
            <>
              <Quantity>{`${props.quantity} 개 /`}</Quantity>
              <Price>{`${localeNumber(props.price)} 원`}</Price>
            </>
          ) : (
            <>
              <Price>0원</Price>
              <Quantity>{`${props.quantity} 개 /`}</Quantity>
              <PriceCancel>{`${localeNumber(props.price)} 원`}</PriceCancel>
            </>
          )}
        </Flex>
      </FlexBetween>
    </Layout>
  );
});

export default ExtraChargeCard;
