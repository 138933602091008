import { observer } from 'mobx-react';
import React from 'react';
import styled from 'styled-components';
import { scaler } from '../../helper/scaler';
import View from '../atoms/view';
import CloseImage from '../../assets/common/close.png';

const Layout = styled(View)`
position: absolute;
top: 0;
left: 0;
right: 0;
bottom: 0;
z-index: 100;
padding: 30px;
@media (max-width: 768px) {
    padding: ${scaler(30)}px;
};
`;

const Background = styled(View)`
position: fixed;
top: 0;
left: 0;
right: 0;
bottom: 0;
background-color: ${({ theme }) => `${theme.colors.black.base}50`};
`;

const CloseButtonView = styled(View)`
align-items: flex-end;
`;

const CloseButton = styled.img`
width: 16px;
height: 16px;
@media (max-width: 768px) {
    width: ${scaler(16)}px;
    height: ${scaler(16)}px;
};
`;

const Message = styled(View)`
flex: 1;
z-index: 1;
background-color: ${({ theme }) => theme.colors.white.base};
padding: 24px ${({ theme }) => theme.webBase.templatePadding}px;
border-radius: 16px;
@media (max-width: 768px) {
    padding: ${scaler(24)}px ${({ theme }) => theme.appBase.templatePadding}px;
    border-radius: ${scaler(16)}px;
};
`;

const TitleView = styled(View)`
align-items: center;
margin-bottom: 16px;
@media (max-width: 768px) {
    margin-bottom: ${scaler(16)}px;
};
`;

const Title = styled(View)`
font-size: ${({ theme }) => theme.webTypography.size.l1}px;
font-weight: ${({ theme }) => theme.webTypography.weight.bold};
color: ${({ theme }) => theme.colors.black[100]};
@media (max-width: 768px) {
    font-size: ${({ theme }) => theme.appTypography.size.l1}px;
};
`;

const ContentView = styled(View)`
margin-bottom: 20px;
`;

const ContentTitle = styled(View)`
font-size: ${({ theme }) => theme.webTypography.size.s1}px;
font-weight: ${({ theme }) => theme.webTypography.weight.bold};
color: ${({ theme }) => theme.colors.black[100]};
@media (max-width: 768px) {
    font-size: ${({ theme }) => theme.appTypography.size.s1}px;
};
`;

const Content = styled(View)`
font-size: ${({ theme }) => theme.webTypography.size.s1}px;
color: ${({ theme }) => theme.colors.black[100]};
margin-bottom: 4px;
@media (max-width: 768px) {
    font-size: ${({ theme }) => theme.appTypography.size.s1}px;
    margin-bottom: ${scaler(4)}px;
};
`;

interface ITermOfServiceModal {
    onPressBack?: () => void;
    onPressBlur?: () => void;
}

const TermOfServiceModal: React.FC<ITermOfServiceModal> = observer((props) => {
    return (
        <Layout>
            <Background onClick={props.onPressBlur}/>
            <Message>
                <CloseButtonView onClick={props.onPressBack}>
                    <CloseButton src={CloseImage} />
                </CloseButtonView>
                <TitleView>
                    <Title>이용약관</Title>
                </TitleView>

                <ContentView>
                    <ContentTitle>{`제 1장 [총칙]`}</ContentTitle>
                    <Content>{`제 1조 (목적)`}</Content>
                    <Content>{`이 약관은 주식회사 물류대장(이하 “회사")이 제공하는 배송 설치 서비스 및 추가자재 결제 서비스의 이용조건, 절차, 권리, 의무 및 기타 필요한 제반 사항을 규정함을 목적으로 합니다. `}</Content>
                    <Content>{`제 2조 (약관의 명시 및 효력)`}</Content>
                    <Content>{`1. “회사"는 이 약관의 내용을 “고객"이 쉽게 알 수 있도록 서비스 화면에 게시합니다. `}</Content>
                    <Content>{`2. “회사"는 약관의 규제에 관한 법률, 정보통신망 이용촉진 및 정보보호 등에 관한 법률 등 관련 법령을 위반하지 않는 범위에서 이 약관을 개정할 수 있습니다. `}</Content>
                    <Content>{`제 3조 (약관 이외의 준칙)`}</Content>
                    <Content>{`이 약관에 언급되지 않은 사항은 개인정보보호법, 전기통신기본법, 전기통신사업법, 정보통신망이용촉진 및 정보보호 등에 관한 법률 및 기타 관련 법령 또는 상관례에 따릅니다.`}</Content>
                    <Content>{`제 4조 (서비스의 종류)`}</Content>
                    <Content>{`1. 배송 설치 서비스: “회사" 또는 회사의 “고객사”가 전문 설치인력을 통해 가구, 가전 및 헬스케어 제품을 배송 및 설치하는 서비스를 제공함을 의미합니다. `}</Content>
                    <Content>{`2. 추가자재 결제 서비스: “회사" 또는 회사의 “고객사”가 배송 설치 서비스를 진행할 때 추가적으로 필요한 자재를 판매 및 환불과 같은 결제와 관련된 서비스를 의미합니다. `}</Content>
                </ContentView>

                <ContentView>
                    <ContentTitle>{`제 2장 [서비스 이용계약]`}</ContentTitle>
                    <Content>{`제 5조 (이용계약의 성립)`}</Content>
                    <Content>{`1. 배송 설치 서비스는 서비스를 이용하고자 하는 자 (이하 “고객")가 회사의 “고객사”에 요청 후 “고객사”가 요청을 승낙한 경우에 성립합니다. 추가자재 결제 서비스 이용계약은 “고객"이 약관에 동의함으로써 성립합니다.`}</Content>
                    <Content>{`2. “회사”는 다음과 같은 사유가 발생한 경우 이용신청에 대한 승낙을 거부하거나 유보할 수 있습니다. `}</Content>
                    <Content>{`① 설비에 여유가 없거나 기술상 지장이 있는 경우`}</Content>
                    <Content>{`② 기타 약관에 위배되거나 위법 또는 부당한 이용신청임이 확인된 경우 및 “회사"가 합리적인 판단에 의하여 필요하다고 인정하는 경우 `}</Content>
                    <Content>{`제 6조 (개인정보의 수정, 보호)`}</Content>
                    <Content>{`“고객"은 이용신청 시 허위의 정보를 제공하여서는 아니되며, 기재한 사항에 변동이 있을 경우에는 즉시 “회사" 또는 “고객사"에 변경을 요청해야합니다. `}</Content>
                    <Content>{`“회사"의 회원에 대한 통지는 “고객"이 제공한 전화번호에 도달함으로써 통지된 것으로 보며, 수정 요청하지 않은 정보로 인하여 발생하는 손해는 “고객”이 전적으로 부담하며, 회사는 이에 대하여 아무런 책임을 지지 않습니다. `}</Content>
                    <Content>{`“회사”는 이용계약을 위하여 “고객"이 제공한 정보를 “고객"이 동의한 “회사" 서비스 운영을 위한 목적 이외의 용도로 사용할 수 없으며, 새로운 이용목적이 발생한 경우 도는 제3자에게 제공하는 경우에는 이용 제공 단계에서 당해 “고객"에게 그 목적을 고지하고 동의를 받습니다. 다만 관련 법령에 달리 정함이 있는 경우에는 예외로 합니다.`}</Content>
                    <Content>{`“회사”는 회원의 개인정보를 보호하기 위해 제3자에게 구매회원의 개인정보를 제공할 필요가 있는 경우에는 실제 청약시에 제공되는 개인정보 항목, 제공받는 자, 제공받는 자의 개인정보 이용목적 및 보유 이용기간 등을 명시하여 구매회원의 동의를 받고, 개인정보를 위탁하는 경우에는 관련 법령이 정하는 바에 따라 개인정보처리방침을 수립하고 개인정보 보호 책임자를 지정하여 이를 게시하고 운영합니다. `}</Content>
                </ContentView>

                <ContentView>
                    <ContentTitle>{`제 3장 [회사 및 고객의 책임과 의무]`}</ContentTitle>
                    <Content>{`제 7조 (회사의 의무)`}</Content>
                    <Content>{`1. “회사"는 관련법과 이 약관이 금지하는 행위를 하지 않으며, 계속적이고 안정적으로 서비스를 제공하기 위하여 최선을 다해 노력합니다. `}</Content>
                    <Content>{`2. “회사"는 이용자가 안전하게 서비스를 이용할 수 있도록 개인정보(신용정보 포함)보호를 위해 개인정보취급방침을 수립하고 공시하고 준수합니다. `}</Content>
                    <Content>{`3. “회사"는 서비스의 제공과 관련하여 알게 된 이용자의 개인정보를 본인의 승낙 없이 제3자에게 누설, 배포하지 않고 이를 보호하기 위하여 노력합니다. 이용자의 개인정보보호에 관한 기타의 사항은 정보통신망법 및 “회사"가 별도로 정한 개인정보관리지침에 다릅니다. `}</Content>
                    <Content>{`4. “회사" 서비스 제공 및 보안과 관련된 설비를 지속적이고 안정적인 서비스 제공에 적합하도록 유지, 점검 또는 복구 등의 조치를 성실히 이행하여야 합니다. `}</Content>
                    <Content>{`제 8조 (고객의 의무)`}</Content>
                    <Content>{`“고객"은 관계법령 및 이 약관의 규정, 회사의 정책, 이용안내 등 회사가 통지 또는 공지하는 사항을 준수하여야 하며, 기타 회사 업무에 방해되는 행위를 하여서는 안됩니다. 회원은 서비스 이용과 관련하여 다음 각 호의 행위를 하여서는 안됩니다. `}</Content>
                    <Content>{`1. 서비스 신청 또는 변경 시 허위내용의 등록`}</Content>
                    <Content>{`2. 회사가 정한 정보 이외의 정보(컴퓨터 프로그램 등)의 송신 또는 게시`}</Content>
                    <Content>{`3. 회사 또는 제3자의 저작권 등 지적 재산권에 대한 침해`}</Content>
                    <Content>{`4. 회사 또는 제3자의 명예를 손상시키거나 업무를 방해하는 행위`}</Content>
                    <Content>{`5. 외설 또는 폭력적인 메시지, 화상, 음성 기타 공공질서 미풍양속에 반하는 정보를 서비스에 공개 또는 게시하는 행위`}</Content>
                    <Content>{`6. 고객센터 상담 내용이 욕설, 폭언, 성희롱 등에 해당하는 행위`}</Content>
                    <Content>{`7. 정당한 사유 없이 회사의 영업을 방해하는 내용을 기재하는 행위`}</Content>
                    <Content>{`8. 리버스엔지니어링, 디컴파일, 디스어셈블 및 기타 일체의 가공행위를 통하여 서비스를 복제, 분해 또 모방 기타 변형하는 행위`}</Content>
                    <Content>{`9. 자동 접속 프로그램 등을 사용하는 등 정상적인 용법과 다른 방법으로 서비스를 이용하여 회사의 서버에 부하를 일으켜 회사의 정상적인 서비스를 방해하는 행위`}</Content>
                    <Content>{`10. 기타 관계법령에 위반된다고 판단되는 행위`}</Content>
                </ContentView>

                <ContentView>
                    <ContentTitle>{`제 4장 [서비스의 이용]`}</ContentTitle>
                    <Content>{`제 9조 (대금 결제)`}</Content>
                    <Content>{`1. “회사"는 회원이 카드, 간편결제 등의 방법으로 매매 대금을 결제할 수 있는 방법을 제공합니다. `}</Content>
                    <Content>{`2. 매매 대금의 결제와 관련하여 “설치 전문가"가 입력한 정보에 대해서 동의한 이후에 발생한 책임과 불이익은 전적으로 “고객"이 부담해야 합니다. `}</Content>
                    <Content>{`3. 서비스 제공 후 일정 기간 내에 매매대금을 결제하지 않는 경우 “회사"는 “고객"에 법적 책임을 물을 수 있습니다. `}</Content>
                    <Content>{`4. “회사"는 “고객"의 상품 결제 체결 내용을 문자 메시지로 전송하고, 결제 취소 방법 및 절차를 안내합니다. `}</Content>
                    <Content>{`5. “회사"는 “고객"이 매매대금 결제 시 사용한 결제수단에 대해 정당한 사용권한을 가지고 있는지의 여부를 확인할 수 있으며, 이에 대한 확인이 완료될 때까지 거래진행을 중지하거나, 확인이 불가한 해당거래를 취소할 수 있습니다. `}</Content>
                    <Content>{`6. “고객이" 실제로 결제하는 금액은 “회사"의 고객사가 책정한 추가 자재 금액입니다. `}</Content>
                    <Content>{`제 10조 (서비스 용역)`}</Content>
                    <Content>{`1. “회사"는 “고객사"로부터 전달받는 주문을 “고객"이 입력한 희망일에 서비스를 제공받을 수 있도록 “설치 전문가"에 안내합니다. `}</Content>
                    <Content>{`2. 천재지변 등 불가항력적인 사유가 발생한 경우 그 해당기간은 배송 설치 용역 소요기간에서 제외됩니다.`}</Content>
                    <Content>{`3. 배송 설치 용역 후, 전문가는 “고객"에게 정상적으로 서비스가 완료되었음을 확인시키며 이에 동의했다는 뜻으로 “고객"은 직접 서명합니다. `}</Content>
                    <Content>{`제 11조 (교환 환불 안내)`}</Content>
                    <Content>{`1. 설치 환경문제 및 설치 불량으로 인한 교환/환불은 설치 KIT를 설치하신 날부터 7일이내까지  접수/환불이 가능합니다.`}</Content>
                    <Content>{`2. 단, 고객님이 받으신 설치 받으신 에어컨과 설치 KIT의 내용이 표시, 광고 내용과 다르거나 계약내용과 다르게 이행된 경우에는 설치한 날로부터 3개월 이내, 그 사실을 안 날 또는 알 수 있었던 날부터 30일이내에 교환/환불이 가능합니다.`}</Content>
                    <Content>{`제 12조 (교환/환불 불가 안내)`}</Content>
                    <Content>{`1. “고객”의 단순변심으로 인한 교환/환불 요청이 설치한 날로부터 7일을 경과한 경우`}</Content>
                    <Content>{`2. “고객”의 책임 있는 사유로 배송 설치 상품의 가치가 파손되거나 훼손된 경우`}</Content>
                    <Content>{`3. “고객"의 사용 또는 일부 소비에 의하여 상품 등의 가치가 현저히 감소된 경우`}</Content>
                    <Content>{`4. 시간이 경과되어 재판매가 곤란할 정도로 상품의 가치가 상실된 경우`}</Content>
                </ContentView>

                <ContentView>
                    <ContentTitle>{`제 5장 [기타]`}</ContentTitle>
                    <Content>{`제 14조 (약관 외 준칙 및 관련 법령과의 관계)`}</Content>
                    <Content>{`1. 이 약관에 명시되지 않은 사항은 전자 상거래등에서의 소비자보호에관한법률 등 관련 법령의 규정과 일반 상관례에 의합니다.`}</Content>
                    <Content>{`2. “회사”가 제공하는 구매 서비스를 통하여 거래한 경우, 전자 상거래 등에서의 소비자보호에관한법률 등 관련 법령이 해당 거래 당사자에게 우선적으로 적용되고, 거래 당사자는 이 약관의 규정을 들어 거래 상대방에 대하여 면책을 주장할 수 없습니다.`}</Content>
                    <Content>{`3. “회사”는 필요한 경우 특정 서비스에 관하여 적용될 사항(이하 “개별약관”이라고 합니다)을 정하여 이를 회사 홈페이지 등을 통해 미리 공지할 수 있습니다.`}</Content>
                    <Content>{`4. “회사”는 필요한 경우 특정 서비스에 관하여 적용될 사항(이하 “개별약관”이라고 합니다)을 정하여 이를 회사 홈페이지 등을 통해 미리 공지할 수 있습니다.`}</Content>
                    <Content>{`5. “고객"은 이 약관 및 개별약관의 내용에 변경이 있는지 여부를 주시하여야 하며, 변경사항의 공지가 있을 시에는 이를 확인하여야 합니다.`}</Content>
                    <Content>{`제 15조 (관할법원)`}</Content>
                    <Content>{`이 약관과 이용계약 및 “고객" 상호간의 분쟁에 대해 “회사”를 당사자로 하는 소송이 제기될 경우에는 “회사”의 본사 소재지를 관할하는 법원을 관할법원으로 정합니다. `}</Content>
                    <Content>{`제 16조 (기타조항)`}</Content>
                    <Content>{`“회사와 “고객"은 상대방의 명백한 동의 없이 이 약관상의 권리와 의무를 제3자에게 양도할 수 없습니다. `}</Content>
                </ContentView>
            </Message>
        </Layout>
    );
});

export default TermOfServiceModal;
