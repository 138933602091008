import React from 'react';
import { observer } from 'mobx-react';
import styled from 'styled-components';
import useWindowSize from '../hooks/useWindowSize';
import AppPages from './appPages';
import spinnerStore from '../store/spinnerStore';
import Spinner from '../components/atoms/spinner';
import termOfServiceModalStore from '../modalStore/termOfServiceModalStore';
import TermOfServiceModal from '../components/modals/termOfServiceModal';
import privatePolicyModalStore from '../modalStore/privetePolicyModalStore';
import PrivatePolicy from '../components/modals/privatePolicy';

const ViewArea = styled.div<{ width?: number, height?: number }>`
display: flex;

// width: ${({ width }) => width ? `${width}px` : `100%`};
// height: ${({ height }) => height ? `${height}px` : `100%`};
`;

interface IAppScreen {

}

const AppScreen: React.FC<IAppScreen> = observer((props) => {
    const windowSize = useWindowSize();

    return (
        <ViewArea 
            width={windowSize.width}
            height={windowSize.height}
        >
            <AppPages />
            {/* 이용약관 모달 */}
            { termOfServiceModalStore.visible && (
                <TermOfServiceModal
                    onPressBlur={() => termOfServiceModalStore.handleModal(false)}
                    onPressBack={() => termOfServiceModalStore.handleModal(false)}
                />
            )}
            {/* 개인정보 처리방침 모달 */}
            { privatePolicyModalStore.visible && (
                <PrivatePolicy
                    onPressBlur={() => privatePolicyModalStore.handleModal(false)}
                    onPressBack={() => privatePolicyModalStore.handleModal(false)}
                />
            )}

            {/* Spinner */}
            { spinnerStore.loading && <Spinner /> }
        </ViewArea>
    );
});

export default AppScreen;