// export const TEST_CLIENT_KEY = 'test_ck_7XZYkKL4Mrjm27k70O1V0zJwlEWR';
// export const TEST_SECRET_KEY = 'test_sk_ZORzdMaqN3wGWBwDYDb35AkYXQGw';

export const TEST_CLIENT_KEY = "test_gck_mBZ1gQ4YVXYO6QZwLROxrl2KPoqN";
export const TEST_SECRET_KEY = "test_gsk_DnyRpQWGrNzWyNO4BGeerKwv1M9E";

// export const LIVE_CLIENT_KEY = "live_ck_OALnQvDd2VJzWLEoXgOVMj7X41mN";
// export const LIVE_SECRET_KEY = "live_sk_YZ1aOwX7K8m4aB6XeEQryQxzvNPG";

export const LIVE_CLIENT_KEY = "live_ck_OALnQvDd2VJzWLEoXgOVMj7X41mN";
export const LIVE_SECRET_KEY = "live_sk_YZ1aOwX7K8m4aB6XeEQryQxzvNPG";
