export type PaymentType = 'card' | 'cash' | 'bankTransfer';

type PaymentOptionType = {
    text: string,
    value: PaymentType,
};

export const PaymentOptions: Array<PaymentOptionType> = [
    {
        text: '카드/간편결제',
        value: 'card',
    },
    {
        text: '계좌이체',
        value: 'bankTransfer',
    },
];