import Main from "../pages/main";
import NotFound from "../pages/notFound";
import PaymentFailed from "../pages/paymentResult/paymentFailed";
import PaymentMiddleSuccess from "../pages/paymentResult/paymentMiddleSuccess";
import PaymentSuccessful from "../pages/paymentResult/paymentSuccessful";

const MainScreens = {
  Main: {
    path: "/main/:host/:p",
    element: <Main />,
  },
};

// NotFount
const NotFoundScreens = {
  NotFound: {
    path: "*",
    element: <NotFound />,
  },
};

// PaymentResult
const PaymentResultScreens = {
  PaymentFailed: {
    path: "/fail/:host/:p",
    element: <PaymentFailed />,
  },
  PaymentMiddleSuccess: {
    path: "/middleSuccess/:host/:orderId/:invoiceSeq/:p",
    element: <PaymentMiddleSuccess />,
  },
  PaymentSuccessful: {
    path: "/success/:host/:p",
    element: <PaymentSuccessful />,
  },
};

export const AppRouteDef = {
  //   ...MainScreens0,
  ...MainScreens,
  ...NotFoundScreens,
  ...PaymentResultScreens,
};
