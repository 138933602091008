import { observer } from 'mobx-react';
import React from 'react';
import styled from 'styled-components';
import { scaler } from '../../helper/scaler';
import View from '../atoms/view';

const Layout = styled(View)`
flex-direction: row;
/* Disable drag option */
-webkit-touch-callout: none;
-webkit-user-select: none;
-khtml-user-select: none;
-moz-user-select: none;
-ms-user-select: none;
user-select: none;
`;

const Content = styled(View)`
font-size: ${({ theme }) => theme.webTypography.size.s1}px;
color: ${({ theme }) => theme.colors.black.base};
@media (max-width: 768px) {
    font-size: ${({ theme }) => theme.appTypography.size.s1}px;
};
:hover {
    cursor: pointer;
};
`;

const Gap = styled(View)`
margin: 0 8px;
@media (max-width: 768px) {
    margin: 0 ${scaler(8)}px;
};
`

interface ITOS {
    onPressTOS?: () => void;
    onPressPrivatePolicy?: () => void;
}

const TOS: React.FC<ITOS> = observer((props) => {
    return (
        <Layout>
            <Content onClick={props.onPressTOS}>이용약관</Content>
            <Gap />
            <Content onClick={props.onPressPrivatePolicy}>개인정보 처리방침</Content>
        </Layout>
    );
});

export default TOS;