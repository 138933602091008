import { HOST_TYPES } from "../constants/hostType";

const divideQuery = (query: string): Array<string> => {
  const queryValues = query.replace("?", "").split("&");
  return queryValues;
};

const findTargetByQueryArray = (
  dividedQueries: Array<string>,
  target: string
): string | undefined => {
  return dividedQueries.find((value) => value.startsWith(target));
};

const getQueryValue = (singleQueryString: string): string => {
  return singleQueryString.split("=")[1];
};

export const getHostByQuery = (query: string): string | undefined => {
  const queryValues = divideQuery(query);
  // NOTE: ex) 'host=dev'
  const _host = findTargetByQueryArray(queryValues, "host");
  if (!_host) {
    return undefined;
  }
  const host = getQueryValue(_host);
  return host;
};

export const getInvoiceSeqByQuery = (query: string): number | undefined => {
  const queryValues = divideQuery(query);
  // NOTE: ex) 'invoiceSeq=12345'
  const _invoiceSeq = findTargetByQueryArray(queryValues, "invoiceSeq");
  if (!_invoiceSeq) {
    return undefined;
  }
  const invoiceSeq = getQueryValue(_invoiceSeq);
  return parseInt(invoiceSeq);
};

export const getResultKey = (query: string): string | undefined => {
  const queryValues = divideQuery(query);
  // NOTE: ex) 'paymentKey=****'
  const _resultKey = findTargetByQueryArray(queryValues, "paymentKey");
  if (!_resultKey) {
    return undefined;
  }
  const resultKey = getQueryValue(_resultKey);
  return resultKey;
};

export const getResultAmount = (query: string): number | undefined => {
  const queryValues = divideQuery(query);
  // NOTE: ex) 'amount=12345'
  const _amount = findTargetByQueryArray(queryValues, "amount");
  if (!_amount) {
    return undefined;
  }
  const amount = getQueryValue(_amount);
  return parseInt(amount);
};

export const getResultP = (query: string): string | undefined => {
  const queryValues = divideQuery(query);
  // NOTE: ex) 'amount=12345'
  const _p = findTargetByQueryArray(queryValues, "p");
  if (!_p) {
    return undefined;
  }
  const p = getQueryValue(_p);
  return p;
};

export const getResultOrderId = (query: string): string | undefined => {
  const queryValues = divideQuery(query);
  // NOTE: ex) 'amount=12345'
  const _orderId = findTargetByQueryArray(queryValues, "orderId");
  if (!_orderId) {
    return undefined;
  }
  const orderId = getQueryValue(_orderId);
  return orderId;
};

export const getResultInvoiceSeq = (query: string): string | undefined => {
  const queryValues = divideQuery(query);
  // NOTE: er) 'orderId=12345'
  const _invoiceSeq = findTargetByQueryArray(queryValues, "orderId");
  if (!_invoiceSeq) {
    return undefined;
  }
  const invoiceSeq = getQueryValue(_invoiceSeq);
  return invoiceSeq;
};

export type defaultURLParamType = {
  host?: HOST_TYPES;
  invoiceSeq?: string;
  p?: string;
};
