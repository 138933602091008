import { LIVE_CLIENT_KEY, TEST_CLIENT_KEY } from "./../keys/keyFiles";
import { TossPaymentsInstance } from "@tosspayments/payment-sdk";
import {
  action,
  computed,
  makeObservable,
  observable,
  runInAction,
} from "mobx";
import { PaymentType } from "../constants/paymentMethod";
import { IStore } from "../helper/storeHelper";
import { ExtraChargePaymentDTO } from "../interfaces/order/api";
import paymentService from "../service/paymentService";
import { HOST_LIST, HOST_TYPES } from "../constants/hostType";
import { generateDateTimeToString } from "../helper/timeHelper";
import { DOMAIN } from "../constants/domain";

interface IPaymentStore {
  host: HOST_TYPES;
  p: string;
  currentTime: string;
  paymentData: ExtraChargePaymentDTO;
  paymentMethod: PaymentType | undefined;
  paymentInstance: TossPaymentsInstance;
}

const initialState: IPaymentStore = {
  host: "dev",
  p: "",
  currentTime: "",
  paymentData: {},
  paymentMethod: undefined,
  paymentInstance: {} as TossPaymentsInstance,
};

class PaymentStore implements IStore {
  host = initialState.host;
  p = initialState.p;
  paymentMethod = initialState.paymentMethod;
  currentTime = initialState.currentTime;
  paymentData = initialState.paymentData;
  paymentInstance = initialState.paymentInstance;

  constructor() {
    makeObservable(this, {
      host: observable,
      p: observable,
      paymentMethod: observable,
      paymentData: observable,
      currentTime: observable,
      paymentInstance: observable,

      clear: action.bound,

      setHost: action.bound,
      setP: action.bound,
      setPaymentMethod: action.bound,
      setPaymentInstance: action.bound,
      setCurrentTime: action.bound,

      setPaymentData: action.bound,
    });
  }

  async generatePaymentInstance() {
    const instance = await paymentService.generatePaymentInstance(
      this.host === "dev" ? TEST_CLIENT_KEY : LIVE_CLIENT_KEY
    );
    // const instance = await paymentService.generatePaymentInstance(
    //   LIVE_CLIENT_KEY
    // );
    runInAction(() => {
      this.setPaymentInstance(instance);
    });
  }

  activePaymentInstance(type: PaymentType) {
    switch (type) {
      // 카드/간편결제
      case "card":
        this.paymentInstance.requestPayment("카드", {
          amount: this.paymentData.amount || 0,
          orderId: this.paymentData.orderId + "",
          orderName: this.paymentData.orderName + "",
          customerName: this.paymentData.receiverName + "",
          successUrl: `${DOMAIN}/middleSuccess/${this.host}/${this.paymentData?.orderId}/${this.paymentData?.invoiceSeq}/${this.p}`,
          failUrl: `${DOMAIN}/fail/${this.host}/${this.paymentData?.invoiceSeq}`,
        });
        break;
      // 계좌이체
      case "bankTransfer":
        this.paymentInstance.requestPayment("계좌이체", {
          amount: this.paymentData.amount || 0,
          orderId: this.paymentData.orderId + "",
          orderName: this.paymentData.orderName + "",
          customerName: this.paymentData.receiverName + "",
          successUrl: `${DOMAIN}/middleSuccess/${this.host}/${this.paymentData?.orderId}/${this.paymentData?.invoiceSeq}/${this.p}`,
          failUrl: `${DOMAIN}/fail/${this.host}/${this.paymentData?.invoiceSeq}`,
        });
        break;
    }
  }

  getPaymentInstance(paymenetKey: string) {}

  setHost(host: HOST_TYPES) {
    runInAction(() => {
      this.host = host;
    });
  }

  setP(p: string) {
    runInAction(() => {
      this.p = p;
    });
  }

  setPaymentMethod(paymentMethod: PaymentType) {
    runInAction(() => {
      this.paymentMethod = paymentMethod;
    });
  }

  setPaymentInstance(paymentInstance: TossPaymentsInstance) {
    runInAction(() => {
      this.paymentInstance = paymentInstance;
    });
  }

  setPaymentData(paymentData: ExtraChargePaymentDTO) {
    runInAction(() => {
      this.paymentData = {
        ...paymentData,
        extraChargesService: paymentData.extraCharges?.filter(
          (ele) => ele.type === "SERVICE"
        ),
        extraChargesComponent: paymentData.extraCharges?.filter(
          (ele) => ele.type !== "SERVICE"
        ),
      };
    });
  }

  setCurrentTime() {
    runInAction(() => {
      const today = new Date();
      this.currentTime = generateDateTimeToString(today);
    });
  }

  get hostUrl(): HOST_LIST {
    switch (this.host) {
      case "host":
        return HOST_LIST.HOST;
      case "body":
        return HOST_LIST.HOST_BODY;
      case "ch_zf":
        return HOST_LIST.HOST_CH_ZF;
      default:
        return HOST_LIST.HOST_DEV;
    }
  }
  clear() {
    runInAction(() => {
      this.host = initialState.host;
      this.p = initialState.p;
      this.paymentData = initialState.paymentData;
      this.paymentMethod = initialState.paymentMethod;
      this.currentTime = initialState.currentTime;
    });
  }
}

const paymentStore = new PaymentStore();
export default paymentStore;
