export type HOST_TYPES = "host" | "body" | "ch_zf" | "dev";
export const HOST_TYPE_LIST = ["host", "body", "ch_zf", "dev"];

export enum HOST_LIST {
  HOST_BODY = "https://lfs-body.logimate.co.kr",
  HOST = "https://lfs.logimate.co.kr",
  HOST_CH_ZF = "https://lfs-temp.logimate.co.kr",
  HOST_DEV = "https://lfs-dev.logimate.co.kr",
  //   HOST_DEV = "localhost:3001",
}
