import {
  makeObservable,
  runInAction,
  action,
  observable,
  computed,
} from "mobx";
import { HOST_LIST, HOST_TYPES } from "../constants/hostType";
import { ExtraChargePaymentDTO, ExtraChargeDTO } from "../interfaces/order/api";
import { IStore } from "./../helper/storeHelper";

interface IPaymentSuccess {
  host: HOST_TYPES | undefined;
  p: string;
  donePayment: ExtraChargePaymentDTO;
}

const initialState: IPaymentSuccess = {
  host: undefined,
  p: "",
  donePayment: {},
};

class PaymentSuccessStore implements IStore {
  host = initialState.host;
  p = initialState.p;
  donePayment = initialState.donePayment;

  constructor() {
    makeObservable(this, {
      host: observable,
      p: observable,
      donePayment: observable,

      setP: action.bound,
      setHost: action.bound,
      setDonePayment: action.bound,

      clear: action.bound,

      hostUrl: computed,
      customerName: computed,
      orderSeq: computed,
      paymentSeq: computed,
      extraCharge: computed,
      totalPurchase: computed,
    });
  }

  setHost(host: HOST_TYPES) {
    runInAction(() => {
      this.host = host;
    });
  }

  setP(p: string) {
    runInAction(() => {
      this.p = p;
    });
  }

  setDonePayment(donePayment: ExtraChargePaymentDTO) {
    runInAction(() => {
      this.donePayment = donePayment;
    });
  }

  get hostUrl(): HOST_LIST {
    switch (this.host) {
      case "host":
        return HOST_LIST.HOST;
      case "body":
        return HOST_LIST.HOST_BODY;
      case "ch_zf":
        return HOST_LIST.HOST_CH_ZF;
      default:
        return HOST_LIST.HOST_DEV;
    }
  }

  get customerName(): string {
    return this.donePayment.orderName ?? "";
  }

  get orderSeq(): string {
    return this.donePayment.orderSeq?.toString() ?? "";
  }

  get paymentSeq(): string {
    return this.donePayment.paymentSeq?.toString() ?? "";
  }

  get extraCharge(): Array<ExtraChargeDTO> {
    return this.donePayment.extraCharges ?? [];
  }

  get totalPurchase(): number {
    return this.donePayment.amount ?? 0;
  }

  clear() {
    runInAction(() => {
      this.host = initialState.host;
      this.p = initialState.p;
      this.donePayment = initialState.donePayment;
    });
  }
}

const paymentSuccessStore = new PaymentSuccessStore();
export default paymentSuccessStore;
