import { observer } from 'mobx-react';
import React from 'react';
import styled from 'styled-components';
import { scaler } from '../../helper/scaler';
import View from '../atoms/view';

const Layout = styled(View)`
`;

const DescriptionView = styled(View)`
margin-bottom: 4px;
@media (max-width: 768px) {
    margin-bottom: ${scaler(4)}px;
}
`;

const Description = styled(View)`
font-size: ${({ theme }) => theme.webTypography.size.s1}px;
font-weight: ${({ theme }) => theme.webTypography.weight.medium};
color: ${({ theme }) => theme.colors.gray[500]};
@media (max-width: 768px) {
    font-size: ${({ theme }) => theme.appTypography.size.s1}px;
}
`;

const BottomDescription = observer(() => {
    return (
        <Layout>
            <DescriptionView>
                <Description>{`주식회사 물류대장 | 대표 김기수, 강태경`}</Description>
            </DescriptionView>
            <DescriptionView>
                <Description>{`사업자등록번호: 390-86-02206`}</Description>
            </DescriptionView>
            <DescriptionView>
                <Description>{`통신판매업: 제 2022-서울용산-1557호`}</Description>
            </DescriptionView>
            <DescriptionView>
                <Description>{`주소: 서울특별시 중구 한강대로 416, 15층 151호 (남대문로5가, 서울스퀘어)`}</Description>
            </DescriptionView>
            <DescriptionView>
                <Description>{`고객센터: 02-2088-7698`}</Description>
            </DescriptionView>
        </Layout>
    );
});

export default BottomDescription;