import {
  getExtraPaymentCustomer,
  postExtraPaymentApprove,
} from "./../constants/envVar";
import {
  ExtraChargeTossPaymentDTO,
  ResponseDTO,
} from "./../interfaces/order/api";
import { ExtraChargePaymentDTO } from "../interfaces/order/api";
import { Instance } from "../factories/axiosFactory";

class OrderService {
  public async getPaymentData(p: string): Promise<ExtraChargePaymentDTO> {
    const requestQuery = `p=${p}`;
    const { data, status } = await Instance.get(
      getExtraPaymentCustomer + "?" + requestQuery
    );
    return data;
  }

  public async requestPayment(
    // 대장쪽으로
    body: ExtraChargeTossPaymentDTO
  ): Promise<ResponseDTO> {
    const rs = await Instance.post(postExtraPaymentApprove, body);
    return rs;
  }
}

const orderService = new OrderService();
export default orderService;
