import { action, makeObservable, observable } from 'mobx';

interface ISpinnerStore {
    loading: boolean;
};

const initialState: ISpinnerStore = {
    loading: false,
};

class SpinnerStore {
    loading = initialState.loading;

    constructor() {
        makeObservable(this, {
            loading: observable,
            setLoading: action.bound,
        });
    };

    setLoading(loading: boolean) {
        this.loading = loading;
    };
};

const spinnerStore = new SpinnerStore();
export default spinnerStore;