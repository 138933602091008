import { observer } from 'mobx-react';
import React from 'react';
import styled from 'styled-components';
import View from '../atoms/view';
import CloseImage from '../../assets/common/close.png';
import { scaler } from '../../helper/scaler';

const Layout = styled(View)`
position: absolute;
top: 0;
left: 0;
right: 0;
bottom: 0;
z-index: 100;
padding: 30px;
@media (max-width: 768px) {
    padding: ${scaler(30)}px;
};
`;

const Background = styled(View)`
position: fixed;
top: 0;
left: 0;
right: 0;
bottom: 0;
background-color: ${({ theme }) => `${theme.colors.black.base}50`};
`;

const CloseButtonView = styled(View)`
align-items: flex-end;
`;

const CloseButton = styled.img`
width: 16px;
height: 16px;
@media (max-width: 768px) {
    width: ${scaler(16)}px;
    height: ${scaler(16)}px;
};
`;

const Message = styled(View)`
flex: 1;
z-index: 1;
background-color: ${({ theme }) => theme.colors.white.base};
padding: 24px ${({ theme }) => theme.webBase.templatePadding}px;
border-radius: 16px;
@media (max-width: 768px) {
    padding: ${scaler(24)}px ${({ theme }) => theme.appBase.templatePadding}px;
    border-radius: ${scaler(16)}px;
};
`;

const TitleView = styled(View)`
align-items: center;
margin-bottom: 16px;
@media (max-width: 768px) {
    margin-bottom: ${scaler(16)}px;
};
`;

const Title = styled(View)`
font-size: ${({ theme }) => theme.webTypography.size.l1}px;
font-weight: ${({ theme }) => theme.webTypography.weight.bold};
color: ${({ theme }) => theme.colors.black[100]};
@media (max-width: 768px) {
    font-size: ${({ theme }) => theme.appTypography.size.l1}px;
};
`;

const ContentView = styled(View)`
margin-bottom: 20px;
`;

const ContentTitle = styled(View)`
font-size: ${({ theme }) => theme.webTypography.size.s1}px;
font-weight: ${({ theme }) => theme.webTypography.weight.bold};
color: ${({ theme }) => theme.colors.black[100]};
@media (max-width: 768px) {
    font-size: ${({ theme }) => theme.appTypography.size.s1}px;
};
`;

const Content = styled(View)`
font-size: ${({ theme }) => theme.webTypography.size.s1}px;
color: ${({ theme }) => theme.colors.black[100]};
margin-bottom: 4px;
@media (max-width: 768px) {
    font-size: ${({ theme }) => theme.appTypography.size.s1}px;
    margin-bottom: ${scaler(4)}px;
};
`;

interface IPrivatePolicy {
    onPressBack?: () => void;
    onPressBlur?: () => void;
}

const PrivatePolicy: React.FC<IPrivatePolicy> = observer((props) => {
    return (
        <Layout>
            <Background onClick={props.onPressBlur}/>
            <Message>
                <CloseButtonView onClick={props.onPressBack}>
                    <CloseButton src={CloseImage} />
                </CloseButtonView>
                <TitleView>
                    <Title>개인정보 처리방침</Title>
                </TitleView>

                <ContentView>
                    <Content>{`< 주식회사 물류대장 >('daejangpay.com'이하 '주식회사 물류대장')은 「개인정보 보호법」 제30조에 따라 정보주체의 개인정보를 보호하고 이와 관련한 고충을 신속하고 원활하게 처리할 수 있도록 하기 위하여 다음과 같이 개인정보 처리방침을 수립·공개합니다.`}</Content>
                    <Content>{`○ 이 개인정보처리방침은 2023년 3월 7부터 적용됩니다.`}</Content>
                </ContentView>

                <ContentView>
                    <ContentTitle>{`제1조(개인정보의 처리 목적)`}</ContentTitle>
                    <Content>{`< 주식회사 물류대장 >은 다음의 목적을 위하여 개인정보를 처리합니다. 처리하고 있는 개인정보는 다음의 목적 이외의 용도로는 이용되지 않으며 이용 목적이 변경되는 경우에는 「개인정보 보호법」 제18조에 따라 별도의 동의를 받는 등 필요한 조치를 이행할 예정입니다.`}</Content>
                    <Content>{`1. 재화 또는 서비스 제공 물품배송, 서비스 제공을 목적으로 개인정보를 처리합니다.`}</Content>
                </ContentView>

                <ContentView>
                    <ContentTitle>{`제2조(개인정보의 처리 및 보유 기간)`}</ContentTitle>
                    <Content>{`① < 주식회사 물류대장 >은 법령에 따른 개인정보 보유·이용기간 또는 정보주체로부터 개인정보를 수집 시에 동의받은 개인정보 보유·이용기간 내에서 개인정보를 처리·보유합니다.`}</Content>
                    <Content>{`② 각각의 개인정보 처리 및 보유 기간은 다음과 같습니다.`}</Content>
                    <Content>{`1. <재화 또는 서비스 제공>`}</Content>
                    <Content>{`<재화 또는 서비스 제공>와 관련한 개인정보는 수집.이용에 관한 동의일로부터 <3년>까지 위 이용목적을 위하여 보유 및 이용됩니다.`}</Content>
                    <Content>{`- 보유근거 : 『전자상거래 등에서의 소비자보호에 관한 법률 시행령』 제6조에 근거하여 사용자의 개인정보를 보유합니다.`}</Content>
                    <Content>{`- 관련법령 :`}</Content>
                    <Content>{`1)소비자의 불만 또는 분쟁처리에 관한 기록 : 3년`}</Content>
                    <Content>{`2) 대금결제 및 재화 등의 공급에 관한 기록 : 5년`}</Content>
                    <Content>{`3) 계약 또는 청약철회 등에 관한 기록 : 5년`}</Content>
                </ContentView>

                <ContentView>
                    <ContentTitle>{`제3조(처리하는 개인정보의 항목)`}</ContentTitle>
                    <Content>{`① < 주식회사 물류대장 >은 다음의 개인정보 항목을 처리하고 있습니다.`}</Content>
                    <Content>{`1. < 재화 또는 서비스 제공 >`}</Content>
                    <Content>{`필수항목 : 휴대전화번호, 자택주소, 이름, 서비스 이용 기록, 결제기록`}</Content>
                </ContentView>

                <ContentView>
                    <ContentTitle>{`제4조(개인정보의 제3자 제공에 관한 사항)`}</ContentTitle>
                    <Content>{`① < 주식회사 물류대장 >은 개인정보를 제1조(개인정보의 처리 목적)에서 명시한 범위 내에서만 처리하며, 정보주체의 동의, 법률의 특별한 규정 등 「개인정보 보호법」 제17조 및 제18조에 해당하는 경우에만 개인정보를 제3자에게 제공합니다.`}</Content>
                    <Content>{`② < 주식회사 물류대장 >은 다음과 같이 개인정보를 제3자에게 제공하고 있습니다.`}</Content>
                    <Content>{`1. 설치전문가 `}</Content>
                    <Content>{`개인정보를 제공받는 자 : 설치전문가`}</Content>
                    <Content>{`제공받는 자의 개인정보 이용목적 : 휴대전화번호, 자택주소, 이름, 신용카드정보, 은행계좌정보, 서비스 이용 기록, 결제기록`}</Content>
                    <Content>{`제공받는 자의 보유.이용기간: 3년`}</Content>
                    <Content>{`2. (주) 파세코 `}</Content>
                    <Content>{`개인정보를 제공받는 자 : (주) 파세코`}</Content>
                    <Content>{`제공받는 자의 개인정보 이용목적 : 휴대전화번호, 자택주소, 이름, 신용카드정보, 은행계좌정보, 서비스 이용 기록, 결제기록`}</Content>
                    <Content>{`제공받는 자의 보유.이용기간: 3년`}</Content>
                </ContentView>

                <ContentView>
                    <ContentTitle>{`제5조(개인정보처리의 위탁에 관한 사항)`}</ContentTitle>
                    <Content>{`① < 주식회사 물류대장 >은 원활한 개인정보 업무처리를 위하여 다음과 같이 개인정보 처리업무를 위탁하고 있습니다.`}</Content>
                    <Content>{`1. < 개인정보 처리위탁 >`}</Content>
                    <Content>{`위탁받는 자 (수탁자) : 토스페이먼츠 주식회사`}</Content>
                    <Content>{`위탁하는 업무의 내용 : 구매 및 요금 결제, 본인인증(금융거래, 금융서비스)`}</Content>
                    <Content>{`위탁기간 : 3년`}</Content>
                    <Content>{`② < 주식회사 물류대장 >은 위탁계약 체결시 「개인정보 보호법」 제26조에 따라 위탁업무 수행목적 외 개인정보 처리금지, 기술적․관리적 보호조치, 재위탁 제한, 수탁자에 대한 관리․감독, 손해배상 등 책임에 관한 사항을 계약서 등 문서에 명시하고, 수탁자가 개인정보를 안전하게 처리하는지를 감독하고 있습니다.`}</Content>
                    <Content>{`③ 위탁업무의 내용이나 수탁자가 변경될 경우에는 지체없이 본 개인정보 처리방침을 통하여 공개하도록 하겠습니다.`}</Content>
                </ContentView>

                <ContentView>
                    <ContentTitle>{`제6조(개인정보의 파기절차 및 파기방법)`}</ContentTitle>
                    <Content>{`① < 주식회사 물류대장 > 은 개인정보 보유기간의 경과, 처리목적 달성 등 개인정보가 불필요하게 되었을 때에는 지체없이 해당 개인정보를 파기합니다.`}</Content>
                    <Content>{`② 정보주체로부터 동의받은 개인정보 보유기간이 경과하거나 처리목적이 달성되었음에도 불구하고 다른 법령에 따라 개인정보를 계속 보존하여야 하는 경우에는, 해당 개인정보를 별도의 데이터베이스(DB)로 옮기거나 보관장소를 달리하여 보존합니다.`}</Content>
                    <Content>{`1. 보존하는 개인정보 항목 : 고객정보, 결제정보`}</Content>
                    <Content>{`③ 개인정보 파기의 절차 및 방법은 다음과 같습니다.`}</Content>
                    <Content>{`1. 파기절차`}</Content>
                    <Content>{`< 주식회사 물류대장 > 은 파기 사유가 발생한 개인정보를 선정하고, < 주식회사 물류대장 > 의 개인정보 보호책임자의 승인을 받아 개인정보를 파기합니다.`}</Content>
                    <Content>{`2. 파기방법`}</Content>
                    <Content>{`전자적 파일 형태의 정보는 기록을 재생할 수 없는 기술적 방법을 사용합니다`}</Content>
                </ContentView>

                <ContentView>
                    <ContentTitle>{`제7조(정보주체와 법정대리인의 권리·의무 및 그 행사방법에 관한 사항)`}</ContentTitle>
                    <Content>{`① 정보주체는 주식회사 물류대장에 대해 언제든지 개인정보 열람·정정·삭제·처리정지 요구 등의 권리를 행사할 수 있습니다.`}</Content>
                    <Content>{`② 제1항에 따른 권리 행사는주식회사 물류대장에 대해 「개인정보 보호법」 시행령 제41조제1항에 따라 서면, 전자우편, 모사전송(FAX) 등을 통하여 하실 수 있으며 주식회사 물류대장은(는) 이에 대해 지체 없이 조치하겠습니다.`}</Content>
                    <Content>{`③ 제1항에 따른 권리 행사는 정보주체의 법정대리인이나 위임을 받은 자 등 대리인을 통하여 하실 수 있습니다.이 경우 “개인정보 처리 방법에 관한 고시(제2020-7호)” 별지 제11호 서식에 따른 위임장을 제출하셔야 합니다.`}</Content>
                    <Content>{`④ 개인정보 열람 및 처리정지 요구는 「개인정보 보호법」 제35조 제4항, 제37조 제2항에 의하여 정보주체의 권리가 제한 될 수 있습니다.`}</Content>
                    <Content>{`⑤ 개인정보의 정정 및 삭제 요구는 다른 법령에서 그 개인정보가 수집 대상으로 명시되어 있는 경우에는 그 삭제를 요구할 수 없습니다.`}</Content>
                    <Content>{`⑥ 주식회사 물류대장은 정보주체 권리에 따른 열람의 요구, 정정·삭제의 요구, 처리정지의 요구 시 열람 등 요구를 한 자가 본인이거나 정당한 대리인인지를 확인합니다.`}</Content>
                </ContentView>

                <ContentView>
                    <ContentTitle>{`제8조(개인정보의 안전성 확보조치에 관한 사항)`}</ContentTitle>
                    <Content>{`< 주식회사 물류대장 >은 개인정보의 안전성 확보를 위해 다음과 같은 조치를 취하고 있습니다.`}</Content>
                    <Content>{`1. 정기적인 자체 감사 실시`}</Content>
                    <Content>{`개인정보 취급 관련 안정성 확보를 위해 정기적(분기 1회)으로 자체 감사를 실시하고 있습니다.`}</Content>
                    <Content>{`2. 해킹 등에 대비한 기술적 대책`}</Content>
                    <Content>{`<주식회사 물류대장>은 해킹이나 컴퓨터 바이러스 등에 의한 개인정보 유출 및 훼손을 막기 위하여 보안프로그램을 설치하고 주기적인 갱신·점검을 하며 외부로부터 접근이 통제된 구역에 시스템을 설치하고 기술적/물리적으로 감시 및 차단하고 있습니다.`}</Content>
                </ContentView>

                <ContentView>
                    <ContentTitle>{`제9조(행태정보의 수집·이용·제공 및 거부 등에 관한 사항)`}</ContentTitle>
                    <Content>{`행태정보의 수집·이용·제공 및 거부등에 관한 사항`}</Content>
                    <Content>{`<개인정보처리자명>은(는) 온라인 맞춤형 광고 등을 위한 행태정보를 수집·이용·제공하지 않습니다.`}</Content>
                </ContentView>

                <ContentView>
                    <ContentTitle>{`제10조(추가적인 이용·제공 판단기준)`}</ContentTitle>
                    <Content>{`< 주식회사 물류대장 >은 ｢개인정보 보호법｣ 제15조제3항 및 제17조제4항에 따라 ｢개인정보 보호법 시행령｣ 제14조의2에 따른 사항을 고려하여 정보주체의 동의 없이 개인정보를 추가적으로 이용·제공할 수 있습니다.`}</Content>
                    <Content>{`이에 따라 < 주식회사 물류대장 >이 정보주체의 동의 없이 추가적인 이용·제공을 하기 위해서 다음과 같은 사항을 고려하였습니다.`}</Content>
                    <Content>{`▶ 개인정보를 추가적으로 이용·제공하려는 목적이 당초 수집 목적과 관련성이 있는지 여부`}</Content>
                    <Content>{`▶ 개인정보를 수집한 정황 또는 처리 관행에 비추어 볼 때 추가적인 이용·제공에 대한 예측 가능성이 있는지 여부`}</Content>
                    <Content>{`▶ 개인정보의 추가적인 이용·제공이 정보주체의 이익을 부당하게 침해하는지 여부`}</Content>
                    <Content>{`▶ 가명처리 또는 암호화 등 안전성 확보에 필요한 조치를 하였는지 여부`}</Content>
                    <Content>{`※ 추가적인 이용·제공 시 고려사항에 대한 판단기준은 사업자/단체 스스로 자율적으로 판단하여 작성·공개함`}</Content>
                </ContentView>

                <ContentView>
                    <ContentTitle>{`제11조 (개인정보 보호책임자에 관한 사항)`}</ContentTitle>
                    <Content>{`① 주식회사 물류대장 은 개인정보 처리에 관한 업무를 총괄해서 책임지고, 개인정보 처리와 관련한 정보주체의 불만처리 및 피해구제 등을 위하여 아래와 같이 개인정보 보호책임자를 지정하고 있습니다.`}</Content>
                    <Content>{`▶ 개인정보 보호책임자`}</Content>
                    <Content>{`성명 :김기수 대표이사`}</Content>
                    <Content>{`연락처 :01088216110, ian@logimate.co.kr`}</Content>
                    <Content>{`※ 개인정보 보호 담당부서로 연결됩니다.`}</Content>
                </ContentView>

                <ContentView>
                    <ContentTitle>{`제12조(정보주체의 권익침해에 대한 구제방법)`}</ContentTitle>
                    <Content>{`정보주체는 개인정보침해로 인한 구제를 받기 위하여 개인정보분쟁조정위원회, 한국인터넷진흥원 개인정보침해신고센터 등에 분쟁해결이나 상담 등을 신청할 수 있습니다. 이 밖에 기타 개인정보침해의 신고, 상담에 대하여는 아래의 기관에 문의하시기 바랍니다.`}</Content>
                    <Content>{`1. 개인정보분쟁조정위원회 : (국번없이) 1833-6972 (www.kopico.go.kr)`}</Content>
                    <Content>{`2. 개인정보침해신고센터 : (국번없이) 118 (privacy.kisa.or.kr)`}</Content>
                    <Content>{`3. 대검찰청 : (국번없이) 1301 (www.spo.go.kr)`}</Content>
                    <Content>{`4. 경찰청 : (국번없이) 182 (ecrm.cyber.go.kr)`}</Content>
                    <Content>{`「개인정보보호법」제35조(개인정보의 열람), 제36조(개인정보의 정정·삭제), 제37조(개인정보의 처리정지 등)의 규정에 의한 요구에 대 하여 공공기관의 장이 행한 처분 또는 부작위로 인하여 권리 또는 이익의 침해를 받은 자는 행정심판법이 정하는 바에 따라 행정심판을 청구할 수 있습니다.`}</Content>
                    <Content>{`※ 행정심판에 대해 자세한 사항은 중앙행정심판위원회(www.simpan.go.kr) 홈페이지를 참고하시기 바랍니다.`}</Content>
                </ContentView>
            </Message>
        </Layout>
    );
});

export default PrivatePolicy;