import React, { useEffect } from "react";
import { observer } from "mobx-react";
import styled from "styled-components";
import View from "../../../components/atoms/view";
import { scaler } from "../../../helper/scaler";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import paymentResultStore from "../../../store/paymentResultStore";
import { initInstance } from "../../../factories/axiosFactory";
import { defaultURLParamType } from "../../../helper/queryUrlHelper";
import spinnerStore from "../../../store/spinnerStore";

const Layout = styled(View)`
  flex: 1;
  align-items: center;
  justify-content: center;
  @media (max-width: 768px) {
    padding: ${({ theme }) => theme.appBase.templatePadding}px;
  }
`;

const MessageBox = styled(View)``;

const BoxLayout = styled(View)`
  align-items: center;
  margin-bottom: 16px;
  @media (max-width: 768px) {
    margin-bottom: ${scaler(16)};
  }
`;

const Title = styled.div`
  font-size: ${({ theme }) => theme.webTypography.size.el3}px;
  font-weight: ${({ theme }) => theme.webTypography.weight.bold};
  color: ${({ theme }) => theme.colors.primary.blue};
  text-align: center;
  margin-bottom: 24px;
  @media (max-width: 768px) {
    font-size: ${({ theme }) => theme.appTypography.size.el3}px;
    margin-bottom: ${scaler(12)}px;
  }
`;

const Content = styled(View)`
  font-size: ${({ theme }) => theme.webTypography.size.l1}px;
  color: ${({ theme }) => theme.colors.black[100]};
  text-align: center;
  margin-bottom: 8px;
  @media (max-width: 768px) {
    font-size: ${({ theme }) => theme.appTypography.size.m1}px;
    margin-bottom: ${scaler(4)}px;
  }
`;

const PaymentMiddleSuccess = observer(() => {
  const navigation = useNavigate();
  const params: defaultURLParamType = useParams();
  const location = useLocation();
  const initQueryValue = (query: string) => {
    const result = paymentResultStore.setResultValues(query);
    return result;
  };

  const sendRequest = async () => {
    try {
      spinnerStore.setLoading(true);
      const response = await paymentResultStore.requestPayment();
      if (response.status === 200) {
        const url = `/success/${params.host}/${params.p}`;
        navigation(url);
      }
      spinnerStore.setLoading(false);
    } catch (error: any) {
      spinnerStore.setLoading(false);
      console.log("error =>", error.response.data.message);
      alert(error.response.data.message);
      // navigation('/NotFound');
    }
  };

  useEffect(() => {
    const validate = initQueryValue(location.search);
    if (!validate) {
      navigation("/NotFound");
      return;
    }
    if (!params.host) {
      navigation("/NotFound");
      return;
    }
    if (!params.invoiceSeq) {
      navigation("/NotFound");
      return;
    }
    // All URL is valid, now start to request our server.
    paymentResultStore.setHost(params.host);
    paymentResultStore.setInvoiceSeq(params.invoiceSeq);

    initInstance(paymentResultStore.hostUrl);
    sendRequest();
  }, []);

  return (
    <Layout>
      <MessageBox>
        <BoxLayout>
          <Title>결제가 진행중입니다.</Title>
          <Content>잠시만 기다려주세요.</Content>
        </BoxLayout>
      </MessageBox>
    </Layout>
  );
});

export default PaymentMiddleSuccess;
