const pad = (targetNumber: number, length: number): string => {
    return targetNumber.toString().length < 2 ? `0${targetNumber}` : `${targetNumber}`;
};

export const generateDateTimeToString = (date: Date) => {
    const year = date.getFullYear().toString();
    const month = pad(date.getMonth() + 1, 2);
    const day = pad(date.getDate(), 2);
    const hour = pad(date.getHours(), 2);
    const minute = pad(date.getMinutes(), 2);
    // const second = pad(date.getSeconds(), 2);

    return `${year}${month}${day}${hour}${minute}`;
};