import { IStore } from './../helper/storeHelper';
import { action, makeObservable, observable, runInAction } from 'mobx';

class TermOfServiceModalStore implements IStore {
    visible = false;

    constructor() {
        makeObservable(this, {
            visible: observable,

            handleModal: action.bound,
            clear: action.bound,
        });
    };

    handleModal(visible: boolean) {
        if (!visible) {
            this.clear();
            return;
        }
        runInAction(() => {
            this.visible = visible;
        });
    }

    clear() {
        runInAction(() => {
            this.visible = false;
        });
    }
};

const termOfServiceModalStore = new TermOfServiceModalStore();
export default termOfServiceModalStore;