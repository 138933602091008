import React from 'react';
import { observer } from 'mobx-react';
import { PulseLoader } from 'react-spinners';
import styled, { useTheme } from 'styled-components';
import View from './view';

const Layout = styled(View)`
position: absolute;
top: 0;
left: 0;
right: 0;
bottom: 0;
flex: 1;
align-items: center;
justify-content: center;
z-index: ${Number.MAX_SAFE_INTEGER};
`;

const Blur = styled(View)`
position: absolute;
top: 0;
left: 0;
right: 0;
bottom: 0;
flex: 1;
background-color: ${({ theme }) => theme.colors.white.base};
opacity: 0.6;
`;

const Spinner = observer(() => {
    const theme = useTheme();

    return (
        <Layout>
            <Blur/>
            <PulseLoader
                color={theme.colors.primary.blue}
            />
        </Layout>
    );
});

export default Spinner;